@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  background: #0a0812;
  scroll-behavior: smooth;
  scroll-padding-top: 150px;
}

/* WhatsApp Floating Button CSS */
.whatsapp-float {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 100px;
  right: 100px;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 1000;
  @media screen and (max-width: 768px) {
    bottom: 50px;
    right: 50px;
  }
}

.whatsapp-icon {
  width: 60px;
  height: 60px;
}

/* 
.contact {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.3862070143222809px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  @media screen and (max-width: 768px) {
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.3862070143222809px;
  }
}

.email {
  margin-top: 5px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  @media screen and (max-width: 768px) {
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 20px;
  }
}

.copy {
  margin-top: 5px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  @media screen and (max-width: 768px) {
    margin-top: 40px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
} */
