article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden] {
  display: none;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
a:focus {
  outline: thin dotted;
}
a:active,
a:hover {
  outline: 0;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
mark {
  background: #ff0;
  color: #000;
}
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}
pre {
  white-space: pre-wrap;
}
q {
  quotes: "\201C""\201D""\2018""\2019";
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 0;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}
button,
input {
  line-height: normal;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  font-size: 15px;
}

html {
  background: #000;
}

body {
  font-family: Didot, "Didot LT STD", "Bodoni MT", "ltc-bodoni-175",
    "Hoefler Text", Garamond, "Times New Roman", serif;
  min-height: 100vh;
  color: #57585c;
  color: var(--color-text);
  background-color: #000;
  overflow: auto;
}

/* Color schemes */
.demo-1 {
  --color-text: #86eae7;
  --color-link: #fff;
  --color-link-hover: #86eae7;
  --color-title: #fff;
}

.demo-2 {
  --color-text: #ff53ff;
  --color-link: #3c48fa;
  --color-link-hover: #fff;
  --color-title: #fff;
}

.demo-3 {
  --color-text: #1ed97c;
  --color-link: #fff;
  --color-link-hover: #fff;
  --color-title: #fff;
}

.demo-4 {
  --color-text: #f0ea42;
  --color-link: #ce8c32;
  --color-link-hover: #fff;
  --color-title: #ce8c32;
}

.demo-5 {
  --color-text: #5c5ee7;
  --color-link: #0ab5e4;
  --color-link-hover: #fff;
  --color-title: #0ab5e4;
}

a {
  text-decoration: none;
  color: var(--color-link);
  outline: none;
}

a:hover,
a:focus {
  color: var(--color-link-hover);
  outline: none;
}

.frame {
  padding: 3rem 5vw;
  text-align: center;
  position: relative;
  z-index: 1000;
}

.frame__title {
  font-size: 1rem;
  margin: 0 0 1rem;
  font-weight: normal;
}

.frame__links {
  display: inline;
}

.frame__github,
.frame__links a:not(:last-child),
.frame__demos a:not(:last-child) {
  margin-right: 1rem;
}

.frame__demos {
  margin: 1rem 0;
}

.frame__demo--current,
.frame__demo--current:hover {
  text-decoration: underline;
  color: var(--color-link);
}

.content {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 13rem);
  position: relative;
  justify-content: flex-start;
  align-items: center;
}

.content__title {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  margin: -5.5vw 0 0 0;
  z-index: 100;
  font-size: 10vw;
  color: var(--color-title);
  line-height: 1;
}

@media screen and (min-width: 53em) {
  .message {
    display: none;
  }
  .frame {
    position: fixed;
    text-align: left;
    z-index: 10000;
    top: 0;
    left: 0;
    display: grid;
    align-content: space-between;
    width: 100%;
    max-width: none;
    height: 100vh;
    padding: 2rem 2.25rem;
    pointer-events: none;
    grid-template-columns: 25% 50% 25%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "title title ... "
      "... ... ..."
      "github demos links";
  }
  .frame__title-wrap {
    grid-area: title;
    display: flex;
  }
  .frame__title {
    margin: 0;
  }
  .frame__github {
    grid-area: github;
    justify-self: start;
    margin: 0;
  }
  .frame__demos {
    margin: 0;
    grid-area: demos;
    justify-self: center;
  }
  .frame__links {
    grid-area: links;
    padding: 0;
    justify-self: end;
  }
  .frame a {
    pointer-events: auto;
  }
  .content {
    height: 100vh;
    justify-content: center;
  }
}
